<template>
    <v-layout align-center justify-center>
        <v-flex xs12 sm10 md6 lg5>
            <v-card class="elevation-12">
                <v-toolbar color="black" dark>
                    <v-toolbar-title>Add New Account</v-toolbar-title>
                </v-toolbar>

                <v-card-text class="px-5">
                    <v-alert
                        :value="true"
                        type="error"
                    >
                        Oops, something went wrong
                    </v-alert>

                    
                    <v-card-actions>
                        <v-btn large block color="info" @click="$router.push('/account/add')">Try Again</v-btn>    
                    </v-card-actions>
                </v-card-text>
            </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
    export default {

        


    }

</script>

