<template>
    <v-container>
        
        <v-layout row wrap justify-center align-center>

            <v-toolbar v-if="accountsLoaded && account" class="mb-3 top-bar" color="grey darken-1" elevation="5" dark>
                <v-toolbar-title>
                    <v-avatar size="30" class="mr-1">
                        <v-img
                            :src="account.profile_pic_small"
                        ></v-img>
                    </v-avatar>
                    <span>{{ account.account_name }}</span>
                </v-toolbar-title>

                <v-spacer></v-spacer>

                <v-tooltip bottom class="ma-2">
                    <v-btn slot="activator" icon @click="showFilters">
                        <v-icon>mdi-filter-variant</v-icon>
                    </v-btn>
                    <span>Filter results</span>
                </v-tooltip>
                
            </v-toolbar>

            <ResultFilter ref="resultFilters" v-model="resultFilters" @input="applyFilters" />
            <Posts :accountID="accountID" :filters="resultFilters" ref="posts" />
        </v-layout>

    </v-container>
</template>


<script>

    import Posts from './Posts';
    import ResultFilter from './ResultFilter';
    import { format, subDays } from 'date-fns';

    export default {

        components: {
            'Posts'     : Posts,
            'ResultFilter'    : ResultFilter
        },

        beforeRouteUpdate: function(to, from, next){
            this.accountID = to.params.accountID;

            this.$nextTick(()=>{
                this.init();
            });

            next();
        },

        beforeRouteLeave: function(to, from, next){
            this.$store.dispatch('accountPosts/clearAll');
            next();
        },

        mounted: function(){
            
            this.accountID = this.$route.params.accountID;
            this.$nextTick(()=>{
                this.init();
            });

            
        },

        data: function(){
            return {
                accountID: 0,
                resultFilters: {
                    dateRange: null
                }
            }
        },

        computed: {

            account: function(){
                return this.$store.getters['socialMediaAccounts/getByID'](this.accountID);
            },

            accountsLoaded: function(){
                return this.$store.state.socialMediaAccounts.isLoaded;
            }
        },

        methods: {
            init: function(){
                this.$store.dispatch('accountPosts/clearAll');

                this.resultFilters = Object.assign(this.resultFilters, {
                    dateRange: null
                });

                this.$refs.posts.init();
                
            },

            showFilters: function(){
                this.$refs.resultFilters.show();
            },

            applyFilters: function(){
                this.$nextTick(()=>{
                    this.$store.dispatch('accountPosts/clearAll');
                    this.$refs.posts.init();
                });
            }
        }

    };
</script>

<style lang="scss" scoped>
    .entry{

        .post-text-container{
            height:4.5em;
            overflow:hidden;
        }

        .info-summary span{
            color:white;
        }
    }

</style>