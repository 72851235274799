import settings from '../settings';
import axios from 'axios';

export default {

    updateSettings: function(formData){
        
        return axios.post(`${settings.apiURLPrefix}/me/settings`, formData);

    }


}