<template>
    <v-list dense>

        <!-- DASHBOARD -->
        <v-list-tile @click="$router.push('/')">
            <v-list-tile-action>
                <v-icon>mdi-home</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
                <v-list-tile-title>Home</v-list-tile-title>
            </v-list-tile-content>
        </v-list-tile>

        <!-- ACCOUNTS -->
        <v-list-tile v-for="account in accounts" :key="`account-${account.id}`" @click="$router.push(`/account/${account.id}/posts`)">
            <v-list-tile-action>
                <v-avatar size="22">
                    <v-img :src="account.profile_pic_small"></v-img>
                </v-avatar>
            </v-list-tile-action>
            <v-list-tile-content>
                <v-list-tile-title>{{ account.account_name }}</v-list-tile-title>
            </v-list-tile-content>
        </v-list-tile>

        <!-- ADD ACCOUNT -->
        <v-list-tile @click="$router.push('/account/add')">
            <v-list-tile-action>
                <v-icon>add</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
                <v-list-tile-title>Add New Account</v-list-tile-title>
            </v-list-tile-content>
        </v-list-tile>

        <!-- SETTINGS -->
        <v-list-tile @click="$router.push('/settings')">
            <v-list-tile-action>
                <v-icon>mdi-account-settings</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
                <v-list-tile-title>Settings</v-list-tile-title>
            </v-list-tile-content>
        </v-list-tile>

        <!-- LOGOUT -->
        <v-list-tile @click="logout">
            <v-list-tile-action>
                <v-icon>mdi-exit-to-app</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
                <v-list-tile-title>Logout</v-list-tile-title>
            </v-list-tile-content>
        </v-list-tile>

    </v-list>
</template>

<script>
    export default {
        computed: {
            accounts: function(){
                return this.$store.state.socialMediaAccounts.accounts;
            }
        },
        methods: {
            logout(){
                this.$store.dispatch('auth/logout');
                this.$router.push('/login');
            }
        }
    }
</script>