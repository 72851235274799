<template>
    <v-app>
        <v-navigation-drawer
            v-model="navOpen"
            v-if="isLoggedIn"
            fixed
            left
            app
            temporary
        >
            <Nav />
        </v-navigation-drawer>
        <v-toolbar color="black" dark fixed app v-if="isLoggedIn">
            <v-toolbar-side-icon @click.stop="navOpen = !navOpen"></v-toolbar-side-icon>
            <v-toolbar-title>Brckts</v-toolbar-title>
        </v-toolbar>
        
        <v-content>
            <v-container fluid fill-height>
                <v-layout justify-center align-center>
                    <v-flex text-xs-center>
                        <router-view></router-view>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-content>
    </v-app>
</template>

<script>
    import Nav from './Nav';

    export default {
        components: {
            'Nav' : Nav
        },
        data: () => ({
            navOpen : false
        }),
        computed: {
            isLoggedIn: function(){
                return this.$store.getters['auth/isLoggedIn'];
            }
        }
    }
</script>
