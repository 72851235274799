import Api from '../../api/Api';

export default {
    namespaced: true,
    state: {
        accounts: [],
        isLoaded: false
    },

    /////////////////////////////////////////////////////////////////////
    getters: {
        
        // get by id
        getByID(state){
            return (id)=>{
                let account = state.accounts.find(elem=>{
                    return elem.id == id;
                });
    
                return account;    
            }
            
        }
    },


    /////////////////////////////////////////////////////////////////////

    mutations: {

        setIsLoaded(state, isLoaded){
            state.isLoaded = isLoaded;
        },

        // set accounts
        setAccounts(state, {accounts}){
            state.accounts = accounts;
        },

        // add account
        addAccount(state, {account}){

            let existingIndex = state.accounts.findIndex((elem)=>{
                return elem.id == account.id;
            });

            if(existingIndex == -1)
            {
                state.accounts.push(account);    
            }
            else
            {
                state.accounts[existingIndex] = Object.assign(state.accounts[existingIndex], account);
            }
            
        },

        // remove account
        removeAccount(state, {accountID}){
            
            let index = state.accounts.findIndex((account)=>{
                return account.id == accountID;
            });

            if(index == -1)
            {
                return;
            }

            state.accounts.splice(index, 1);
        }

    },

    /////////////////////////////////////////////////////////////////////

    actions: {

        // get all existing accounts
        getAll({state, commit, rootState}){
            return new Promise((resolve, reject) => {
                
                Api.socialMediaAccounts.getAll().then( resp => {

                    commit('setAccounts', {
                        accounts: resp.data.data.accounts
                    });

                    commit('setIsLoaded', true);
                    
                    resolve(resp.data.data.accounts);

                }).catch(err => {
    
                    reject(err);

                });

            });
        },

        //add facebook page
        addFacebookPage({state, commit, rootState}, {accountID, pageID, instagramAccountID}){
            
            return new Promise((resolve, reject) => {
                
                Api.socialMediaAccounts.addFacebookPage(accountID, pageID, instagramAccountID).then( resp => {

                    resp.data.data.accounts.map((account)=>{
                        commit('addAccount', {
                            account: account
                        });
                    });
                    
                    resolve(resp.data.data.accounts);

                }).catch(err => {
    
                    reject(err);

                });

            });
        },

         //remove account
         removeAccount({state, commit, rootState}, {accountID}){
            
            return new Promise((resolve, reject) => {
                
                Api.socialMediaAccounts.removeAccount(accountID).then( resp => {

                    commit('removeAccount', {
                        accountID: accountID
                    });
                    resolve();

                }).catch(err => {
    
                    reject(err);

                });

            });
            

        }
    }
};