<template>
    <v-container>
        
        <v-layout row wrap justify-center align-center>

            <v-flex v-for="account in accounts" :key="`account-${account.id}`" lg3 md6 xs12 class="pa-3">

                <v-card height="100%" class="account" elevation="5">
                    <v-img
                        :src="account.profile_pic_large"
                        aspect-ratio="1.5"
                        gradient="to bottom, rgba(0,0,0,0), rgba(0,0,0,.3)"
                    >

                    </v-img>

                    <!-- ACCOUNT NAME -->
                    <v-card-text class="text-sm-center">

                        <div class="headline account-name">
                            
                            <span v-line-clamp:20="3">
                                {{ account.account_name }}
                            </span>
                        </div>
                    </v-card-text>

                    <v-divider />

                    <!-- SUMMARY -->
                    <v-card-text>    
                        <v-layout row justify-center align-center class="text-sm-center">

                            <v-flex xs4>
                                <v-avatar color="grey" class="account-info-summary">
                                    <v-icon dark>{{ `mdi-${account.platform}`}}</v-icon>
                                </v-avatar>
                                <br />
                                Platform
                            </v-flex>

                            <v-flex xs4>
                                <v-avatar color="grey" class="account-info-summary">
                                    <span>{{ abbreviateNumber(account.followers_count) }}</span>
                                </v-avatar>
                                <br />
                                Followers
                            </v-flex>

                        </v-layout>
                    </v-card-text>

                    <v-divider />

                    <!-- ACTIONS -->
                    <v-card-text>
                        <v-card-actions class="pl-0 ml-0" justify-center>
                            
                            <v-spacer />

                            <v-tooltip top class="ma-2">
                                <v-btn slot="activator" color="info" @click="$router.push(`/account/${account.id}/posts`)">
                                    View
                                    <v-icon class="ml-1">mdi-eye-plus</v-icon>
                                </v-btn>
                                <span>View Posts</span>
                            </v-tooltip>
                            
                            <v-tooltip top class="ma-2">
                                <v-btn slot="activator" color="error" @click="confirmDelete(account)">
                                    Delete
                                    <v-icon class="ml-1">mdi-delete</v-icon>
                                </v-btn>
                                <span>Delete</span>
                            </v-tooltip>

                            <v-spacer />
                        </v-card-actions>
                    </v-card-text>

                </v-card>

            </v-flex>

        </v-layout>


        <!-- ADD NEW ACCOUNT -->
            <p class="title" v-if="accounts.length == 0">
                You have not connected to any social media account yet.
            </p>

            <v-btn large class="info my-5" @click="$router.push('/account/add')" elevation="5">
                Add New Account
                <v-icon class="ml-1">mdi-plus</v-icon>
            </v-btn>
        <!-- END ADD NEW ACCOUNT -->

        <!-- CONFIRM DELETE DIALOG -->
            <v-dialog
                v-model="isConfirmingAccountDeletion"
                max-width="290"
            >
                <v-card v-if="isConfirmingAccountDeletion">
                    <v-card-title class="headline">Delete Account</v-card-title>

                    <v-card-text>
                        Are you sure you wish to delete this account?
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn
                            color="info"
                            flat="flat"
                            @click="cancelDeleteAccount()"
                        >
                            Cancel
                        </v-btn>

                        <v-btn
                            color="error"
                            flat="flat"
                            @click="deleteAccount(currentDeletingAccount.id)"
                        >
                            Delete
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        <!-- END CONFIRM DELETE DIALOG -->


        <!-- DELETING DIALOG -->
            <v-dialog
                v-model="isDeletingAccount"
                max-width="290"
                persistent
            >
                <v-card>
                    <v-card-title class="headline">Delete Account</v-card-title>
                    <v-card-text class="text-sm-center">
                        Please wait...
                        <br />
                        <v-progress-circular
                            color="info"
                            indeterminate
                        ></v-progress-circular>
                    </v-card-text>
                    
                </v-card>
                
            </v-dialog>
        <!-- END DELETING DIALOG -->

    </v-container>
</template>

<script>
    export default {
        mounted() {
            this.redirectIfNotLoggedIn();
        },
        
        data: function(){
            return {
                currentDeletingAccount: null,
                isDeletingAccount: false
            }
        },

        computed: {
            accounts: function(){
                return this.$store.state.socialMediaAccounts.accounts;
            },

            isConfirmingAccountDeletion: function(){
                return !this.isDeletingAccount && this.currentDeletingAccount != null;
            }
        },

        methods: {
            confirmDelete: function(account){
                console.log(JSON.parse(JSON.stringify(account)));
                this.currentDeletingAccount = JSON.parse(JSON.stringify(account));
            },

            cancelDeleteAccount: function(){
                this.currentDeletingAccount = null;
            },

            deleteAccount: function(accountID){
                this.isDeletingAccount = true;

                this.$store.dispatch('socialMediaAccounts/removeAccount', {
                    accountID : accountID
                }).then(()=>{
                    
                    this.isDeletingAccount = false;
                    this.currentDeletingAccount = null;

                }).catch((err)=>{
                    alert(err);
                }).finally(()=>{

                    this.isDeletingAccount = false;
                    this.currentDeletingAccount = null;

                });
                
            }
        }
    }
</script>

<style lang="scss" scoped>
    .account{
        .account-name{
            overflow:hidden;
            height:3em;
        }

        .account-info-summary{
            color:white;
        }
    }

    .add-new-account{
        border:5px dotted rgba(0,0,0,0.2);
        height:100%;
    }
    
</style>
