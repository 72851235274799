import Api from '../../api/Api';

export default {
    namespaced: true,
    state: {
        posts: [],
        cursorData: ''
    },

    /////////////////////////////////////////////////////////////////////
    getters: {
        
    },


    /////////////////////////////////////////////////////////////////////

    mutations: {

        // clear posts
        clearPosts(state){
            state.posts = [];
            state.cursorData = '';
        },

        // append posts
        appendPosts(state, {posts}){
            state.posts = state.posts.concat(posts);
        },

        // set cursor data
        setCursorData(state, {cursorData}){
            state.cursorData = cursorData;
        }

    },

    /////////////////////////////////////////////////////////////////////

    actions: {

        clearAll({state, commit, rootState}){
            commit('clearPosts');
        },

        // get all posts
        getAll({state, commit, rootState}, {accountID, cursorData, filters}){
            return new Promise((resolve, reject) => {
                
                Api.accountPosts.getAll(accountID, cursorData, filters).then( resp => {

                    commit('appendPosts', {
                        posts: resp.data.data.posts
                    });

                    commit('setCursorData', {
                        cursorData: resp.data.data.cursorData
                    });
                    
                    resolve(state.posts);

                }).catch(err => {
    
                    reject(err);

                });

            });
        }
    }
};