import Home from '../views/home/Home.vue'
import Login from '../views/auth/Login.vue'
import AddAccount from '../views/account/AddAccount'
import AddAccountError from '../views/account/AddAccountError'
import ChooseFacebookPage from '../views/account/chooseFacebookPage/ChooseFacebookPage'
import ViewPosts from '../views/account/viewPosts/ViewPosts'
import Settings from '../views/users/Settings'


export default [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/login',
        name: 'login',
        component: Login
    },

    {
        path: '/account/add',
        name: 'account/add',
        component: AddAccount
    },

    {
        path: '/account/login-error',
        name: 'account/login-error',
        component: AddAccountError
    },

    {
        path: '/account/choose-facebook-page/:accountID',
        name: 'account/choose-facebook-page',
        component: ChooseFacebookPage
    },

    {
        path: '/account/:accountID/posts',
        name: 'account/posts',
        component: ViewPosts
    },

    {
        path: '/settings',
        name: 'settings',
        component: Settings
    }
]