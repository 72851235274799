import Api from '../../api/Api';
import storage from 'local-storage-fallback';
import jwt_decode from 'jwt-decode';
import moment from 'moment';

export default {
    namespaced: true,
    state: {
        authToken: "",
        email: "",
        tokenExpires: null

    },

    /////////////////////////////////////////////////////////////////////
    getters: {
        // is logged in?
        isLoggedIn: (state)=>{
            return state.authToken != "";
        },
        

        //token expired?
        tokenExpired: (state)=>(()=>{
            if(state.tokenExpires == null) return true;

            if(moment().diff(state.tokenExpires, 'seconds') >= -60)
            {
                return true;
            }
            else
            {
                return false;
            }
        })
    },


    /////////////////////////////////////////////////////////////////////

    mutations: {

        // set token
        setToken(state, {authToken}){
            state.authToken = authToken;
            storage.setItem('auth.authToken', authToken);

            state.tokenExpires = null;

            if(state.authToken != "")
            {
                try{
                    let decodedToken = jwt_decode(state.authToken);
                    state.tokenExpires = moment.unix(decodedToken.exp);
                }
                catch(err){
                    console.error(err);
                }
            }
        },

        // set account
        setEmail(state, {email}){
            state.email = email;
            storage.setItem('auth.email', email);
        }

    },

    /////////////////////////////////////////////////////////////////////

    actions: {

        //restore details
        restoreFromStorage({state, commit, rootState}){
            commit('setToken', {
                authToken   : storage.getItem('auth.authToken') || ''
            });

            commit('setEmail', {
                email       : storage.getItem('auth.email') || ''
            })
        },
        
        //logout
        logout({state, commit, rootState}){
            commit('setToken', {
                authToken   : ""
            });

            commit('setEmail', {
                email       : ""
            });
        },

        //refresh token
        refreshToken({state, commit, rootState}){

            return new Promise((resolve, reject)=>{

                Api.auth.refreshToken(state.authToken).then((resp)=>{

                    if(resp.headers.authorization)
                    {
                        let newToken = resp.headers.authorization.replace(/^Bearer /, '');
                        commit('setToken', {
                            authToken   : newToken
                        });

                        resolve(state.authToken);
                    }
                    else
                    {
                        reject('Oops something went wrong! Please contact the administrator.');
                        return;
                    }
                    
                }).catch((err)=>{

                    reject(err);

                });

            });

        },

        //attempt to login
        attemptLogin({state, commit, rootState}, {email, password}){

            return new Promise((resolve, reject)=>{
                Api.auth.attemptLogin(email, password).then((resp)=>{
                
                    if(resp.headers.authorization)
                    {
                        commit('setEmail', {
                            email       : email
                        });

                        commit('setToken', {
                            authToken   : resp.headers.authorization
                        });

                        resolve();
                    }
                    else
                    {
                        reject('Oops something went wrong! Please contact the administrator.');
                        return;
                    }
    
                }).catch((error)=>{

                    console.error('Auth Store::attemptLogin');
                    console.error(error);

                    if (error.response && error.response.status == 400)
                    {
                        reject("Invalid credentials");
                        return;
                    }    
                    
                    reject('Oops something went wrong! Please contact the administrator.');
                    return;

    
                });
            });

            
        },

        //update settings
        updateSettings({state, commit, rootState}, formData){

            return Api.users.updateSettings(formData).then(resp => {

                commit('setEmail', {
                    email       : resp.data.data.email
                });

            }).catch(err => {

                return Promise.reject(err);

            });

        }
    }
};