<template>
    <div>
        <v-expand-transition>
            <v-progress-circular
                indeterminate
                color="primary"
                v-if="pagesAreLoading"
            ></v-progress-circular>
        </v-expand-transition>

        <v-expand-transition>
            <div v-if="!pagesAreLoading" class="text-sm-left pt-3">
                <div class="title mb-2">Select which Facebook page to connect to</div>
                
                <v-select
                    :value="value"
                    @change="onPageChange"
                    :items="pages"
                    label="Select Facebook Page"
                ></v-select>
            </div>
        </v-expand-transition>

        <v-expand-transition>
            <v-card-actions v-if="!pagesAreLoading">
                <v-spacer />
                <v-btn 
                    color="info" 
                    @click="next"
                >Next</v-btn>    
            </v-card-actions>
        </v-expand-transition>
    </div>
</template>

<script>

    import Api from '../../../api/Api';

    export default {

        mounted: function(){
            this.fetchPages();
        },

        props: {
            accountID: null,
            value:""
        },

        computed: {
        },

        data: function(){
            return {

                errorMsg: '',
                pagesAreLoading: true,
                isSubmitting: false,
                pages: []

            };
        },

        methods: {
            init(){
                //do nothing
            },

            fetchPages(){
                
                this.pagesAreLoading = true;

                Api.facebookPages.getPages(this.accountID).then( resp => {
                    this.pages = resp.data.data.pages.map(page => {
                        return {
                            text: page.account_name,
                            value: page
                        };
                    });
                })
                .catch(err => {
                    this.errorMsg = err;
                })
                .finally(()=>{
                    this.pagesAreLoading = false;
                });
            },

            next(){
                this.$emit('next')
            },

            onPageChange(page){
                this.$emit('input', page);
            }
        }

    };
</script>