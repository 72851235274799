<template>
    <div>
        <v-expand-transition>
            <v-progress-circular
                indeterminate
                color="primary"
                v-if="isLoading"
            ></v-progress-circular>
        </v-expand-transition>

        <v-expand-transition>
            <div v-if="!isLoading" class="text-sm-left pt-3">
                <div class="title mb-2">Do you wish to connect to the linked Instagram account as well?</div>

                <v-switch 
                    v-if="instagramAccount != null"
                    :label="`Connect to ${instagramAccount.account_name}`"
                    :value="instagramAccount"
                    :input-value="value"
                    @change="onSelectionChange"
                    color="primary"
                ></v-switch>

                <div class="font-italic mt-3" v-if="instagramAccount == null">
                    There are no linked Instagram accounts for this Facebook page.
                </div>
                
            </div>
        </v-expand-transition>

        <v-expand-transition>
            <v-card-actions v-if="!isLoading">
                <v-spacer />
                <v-btn 
                    color="info" 
                    @click="next"
                >Next</v-btn>    
            </v-card-actions>
        </v-expand-transition>
    </div>
</template>

<script>

    import Api from '../../../api/Api';
    
    export default {

        mounted: function(){
        },

        props: {
            accountID: null,
            page: null,
            value: null
        },

        computed: {
            
        },

        data: function(){
            return {

                errorMsg: '',
                isLoading: true,
                instagramAccount: null

            };
        },

        methods: {
            init(){
                this.isLoading = true;

                Api.facebookPages.getInstagramAccount(this.accountID, this.page.account_id).then( resp => {

                    this.instagramAccount = resp.data.data.account;

                })
                .catch(err => {

                    this.errorMsg = err;

                })
                .finally(()=>{

                    this.isLoading = false;
                    
                });

            },

            next(){
                this.$emit('next')
            },

            onSelectionChange(value){
                this.$emit('input', value);
            }
        }

    };
</script>