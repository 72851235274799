<template>
    <div>
        <v-expand-transition>
            <v-progress-circular
                indeterminate
                color="primary"
                v-if="isLoading"
            ></v-progress-circular>
        </v-expand-transition>

        <v-expand-transition>
            <div v-if="!isLoading" class="text-sm-left pt-3">
                <div class="title mb-2">You are adding these accounts:</div>

                <v-list class="mb-3">
                    <v-divider />

                    <template v-for="(account, index) in accounts">
                        <v-list-tile :key="`account-${index}`" class="py-1">
                            <v-list-tile-avatar>
                                <img :src="account.profile_pic_small" />
                            </v-list-tile-avatar>

                            <v-list-tile-content>
                                <v-list-tile-title v-html="account.account_name"></v-list-tile-title>
                                <v-list-tile-sub-title v-html="account.platform"></v-list-tile-sub-title>
                            </v-list-tile-content>
                        </v-list-tile>
                        <v-divider :key="`account-divider-${index}`" />
                    </template>
                </v-list>
                
            </div>
        </v-expand-transition>

        <v-expand-transition>
            <v-card-actions v-if="!isLoading">
                <v-spacer />
                <v-btn 
                    color="info" 
                    @click="next"
                >Confirm &amp; Submit</v-btn>    
            </v-card-actions>
        </v-expand-transition>
    </div>
</template>

<script>

    import Api from '../../../api/Api';
    
    export default {

        mounted: function(){
        },

        props: {
            accounts: null
        },

        computed: {
            
        },

        data: function(){
            return {

                errorMsg: '',
                isLoading: false

            };
        },

        methods: {
            init(){
                //do nothing

            },

            next(){
                this.$emit('next')
            }
        }

    };
</script>