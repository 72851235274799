import settings from '../settings';
import axios from 'axios';

export default {

    attemptLogin: function(email, password){

        return axios.post(`${settings.apiURLPrefix}/auth/login`, {
            email       : email,
            password    : password
        });

    },

    refreshToken: function(token){
        return axios.get(`${settings.apiURLPrefix}/auth/refresh`, {
            params: {
                token: token
            }
        });
    }


}


let endpoints = {
    login           : `${settings.apiURLPrefix}/auth/login`,
    refreshToken    : `${settings.apiURLPrefix}/auth/refresh`
};

export {endpoints};