import Vue from 'vue'
import VueRouter from 'vue-router'
import Vuetify from 'vuetify'
import '../sass/app.scss'
import Vuex from 'vuex'
import AuthMixins from './mixins/AuthMixins';
import FormatterMixins from './mixins/FormatterMixins';
import LineClamp from 'vue-line-clamp'

import axios from 'axios'

import Store from './store/Store';
import App from './components/App.vue'
import Routes from './routes/Routes';
import {endpoints as AuthEndpoints} from './api/AuthApi';



/*********************************************/
/* VUE ROUTER */
/*********************************************/

const router = new VueRouter({
    mode: 'history',
    routes: Routes,
});

/*********************************************/
/* VUE APP */
/*********************************************/

Vue.use(VueRouter);
Vue.use(Vuetify);
Vue.use(Vuex);
Vue.use(LineClamp);
Vue.mixin(AuthMixins);
Vue.mixin(FormatterMixins);

const store = new Vuex.Store(Store);

const app = new Vue({
    el: '#app',
    components: { App },
    store,
    router,
    created: function(){

        //restore auth
        this.$store.dispatch('auth/restoreFromStorage');

        // auth request interceptor: inject authorization header
        axios.interceptors.request.use((config)=>{            
            
            return new Promise((resolve, reject)=>{
                
                let token = this.$store.state.auth.authToken;
                //have token
                if(token)
                {
                    config.headers['Authorization'] = `bearer ${token}`;
                    
                    //expired?
                    let tokenExpired = this.$store.getters['auth/tokenExpired']();
                    if(tokenExpired && config.url != AuthEndpoints.refreshToken && config.url != AuthEndpoints.login)
                    {
                        this.$store.dispatch('auth/refreshToken').then((newToken)=>{

                            config.headers['Authorization'] = `bearer ${newToken}`;
                            resolve(config);
                            return;

                        }).catch(err=>{
                            reject(err);
                            return;
                        });
                    }
                    else
                    {
                        resolve(config);
                        return;
                    }
                }
                else{
                    resolve(config);
                    return;
                }
            });

            

        }, (error)=>{

            return Promise.reject(error);

        });

        // 401 interceptor
        axios.interceptors.response.use((response)=>{            
            
            return response;

        }, (error)=>{

            if (error.response && error.response.status && error.response.status == 401)
            {
                this.$store.dispatch('auth/logout');
                this.$router.push('/login');
            }

            return Promise.reject(error);

        });

        //restore accounts
        this.$store.dispatch('socialMediaAccounts/getAll');
    }
});