import AuthApi from './AuthApi';
import FacebookPagesApi from './FacebookPagesApi';
import SocialMediaAccountsApi from './SocialMediaAccountsApi';
import AccountPostsApi from './AccountPostsApi';
import UsersApi from './UsersApi';

export default {
    'auth'                      : AuthApi,
    'facebookPages'             : FacebookPagesApi,
    'socialMediaAccounts'       : SocialMediaAccountsApi,
    'accountPosts'              : AccountPostsApi,
    'users'                     : UsersApi
};