import Auth from './modules/Auth';
import SocialMediaAccounts from './modules/SocialMediaAccounts';
import AccountPosts from './modules/AccountPosts';

export default {
    modules: {
        'auth'                  : Auth,
        'socialMediaAccounts'   : SocialMediaAccounts,
        'accountPosts'          : AccountPosts
    }
};