export default {

    methods: {
        redirectIfNotLoggedIn: function(){
            if(!this.$store.getters['auth/isLoggedIn'])
            {
                this.$router.push('/login');
            }
        },

        onLogin: function(){
            this.$router.push('/');
            this.$store.dispatch('socialMediaAccounts/getAll');
        }


    }

};