import settings from '../settings';
import axios from 'axios';

export default {

    getAll: function(accountID, cursorData, filters){
        
        return axios.post(`${settings.apiURLPrefix}/account/${accountID}/posts`, {
            
            cursorData  : cursorData,
            filters     : filters
            
        });

    }


}