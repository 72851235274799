<template>
    <v-container>
        
        <v-layout row wrap justify-center align-center>

            <v-flex v-for="post in posts" :key="`post-${post.id}`" lg3 md6 xs12 class="pa-3">
                
                <v-card class="entry">
                    <v-img
                        :src="post.image || require('../../../../img/placeholders/no_image.png')"
                        aspect-ratio="1.5"
                        gradient="to bottom, rgba(0,0,0,0), rgba(0,0,0,.3)"
                    >

                    </v-img>
                    <v-card-text class="text-sm-center mb-3">
                        <div class="post-text-container">
                            <div class="message" v-line-clamp:20="3">

                                {{ post.text }}
                                
                            </div>
                        </div>
                    </v-card-text>

                    <v-divider />

                        <v-card-text>
                            <v-layout row justify-center align-center class="text-sm-center">

                                <v-flex xs6>
                                    <div class="metadata-label">Posted</div>
                                    <div class="metadata-value">
                                        {{ formatDate(post.published_at) }}
                                    </div>
                                </v-flex>

                                <v-flex xs6>
                                    <div class="metadata-label">Type</div>
                                    <div class="metadata-value">
                                        {{ post.type }}
                                    </div>
                                </v-flex>

                            </v-layout>
                        </v-card-text>

                    <v-divider />

                    <v-card-text>
                        <v-layout row justify-center align-center class="text-sm-center">

                            <v-flex xs4>
                                <v-avatar color="grey" dark class="info-summary">
                                    <span>{{ abbreviateNumber(post.impressions) }}</span>
                                </v-avatar>
                                <br />
                                Impressions
                            </v-flex>

                            <v-flex xs4>
                                <v-avatar color="grey" class="info-summary">
                                    <span>{{ abbreviateNumber(post.comments) }}</span>
                                </v-avatar>
                                <br />
                                Comments
                            </v-flex>

                            <v-flex xs4>
                                <v-avatar color="grey" dark class="info-summary">
                                    <span>{{ abbreviateNumber(post.reactions) }}</span>
                                </v-avatar>
                                <br />
                                Reactions
                            </v-flex>

                        </v-layout>
                    </v-card-text>

                    <v-divider />

                    <v-card-actions>
                        <v-spacer />

                            <v-tooltip top class="ma-2">
                                <v-btn slot="activator" color="info" :href="post.url" target="_blank">
                                    View
                                    <v-icon class="ml-1">mdi-eye-plus</v-icon>
                                </v-btn>
                                <span>View original post</span>
                            </v-tooltip>
                            
                            <v-tooltip top class="ma-2">
                                <v-btn slot="activator" color="info" :href="`/api/account/${accountID}/post/${post.platform_post_id}/export?token=${$store.state.auth.authToken}`" target="_blank">
                                    Export
                                    <v-icon class="ml-1">mdi-export</v-icon>
                                </v-btn>
                                <span>Export comments</span>
                            </v-tooltip>

                        <v-spacer />
                    </v-card-actions>
                </v-card>

            </v-flex>
        </v-layout>

        <v-btn large color="info" class="my-5" v-if="!isLoadingPage" @click="loadNextPage()">
            Load More
            <v-icon class="ml-1">
                mdi-reload
            </v-icon>
        </v-btn>

        <v-progress-circular
            class="my-5" 
            indeterminate
            color='info'
            v-if="isLoadingPage"
        ></v-progress-circular>

    </v-container>
</template>


<script>

    export default {

        mounted: function(){
            this.redirectIfNotLoggedIn();
        },

        props: {
            accountID: 0,
            filters: {
                dateRange: null
            }
        },

        data: function(){
            return {
                page: 1,
                isLoadingPage: false,
                errorMsg: ''
            }
        },

        computed: {

            posts: function(){
                return this.$store.state.accountPosts.posts;
            },

            cursorData: function(){
                return this.$store.state.accountPosts.cursorData;
            }
        },

        methods: {
            init: function(){
                this.$store.dispatch('accountPosts/clearAll');
                this.loadPage('');
            },

            loadNextPage: function(){
                this.loadPage(this.cursorData)
            },

            loadPage: function(cursorData){

                this.errorMsg = '';
                this.isLoadingPage = true;

                this.$store.dispatch('accountPosts/getAll', {
                    accountID   : this.accountID,
                    cursorData  : cursorData,
                    filters     : this.filters
                }).then(()=>{

                    this.page = page;
                    this.isLoadingPage = false;

                }).catch(err=>{

                    this.errorMsg = err;    

                }).finally(()=>{
                    
                    this.isLoadingPage = false;

                });
            }
        }

    };
</script>

<style lang="scss" scoped>
    .entry{

        .post-text-container{
            height:4.5em;
            overflow:hidden;
        }

        .info-summary span{
            color:white;
        }
    }

</style>