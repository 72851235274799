import settings from '../settings';
import axios from 'axios';

export default {

    getPages: function(accountID){

        return axios.get(`${settings.apiURLPrefix}/account/facebook/${accountID}/get-pages`);

    },

    getInstagramAccount: function(accountID, pageID){

        return axios.get(`${settings.apiURLPrefix}/account/facebook/${accountID}/${pageID}/get-instagram-account`);

    }


}