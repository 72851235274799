<template>
    <v-layout align-center justify-center>
        <v-flex xs12 sm10 md6 lg5>
            <v-card class="elevation-12">
                <v-toolbar color="black" dark>
                    <v-toolbar-title>Add New Account</v-toolbar-title>
                </v-toolbar>

                <v-card-text class="pa-5">
                    <v-card-actions>

                        <v-layout row wrap align-center justify-center>
                            <v-flex lg6 md12 class="pa-1">
                                <v-btn 
                                    color="#3C5A99" 
                                    dark 
                                    block 
                                    large 
                                    href="/account/login/facebook"
                                >
                                    <v-icon class="mr-2">mdi-facebook</v-icon>
                                    Facebook
                                </v-btn>
                            </v-flex>

                        </v-layout>

                    </v-card-actions>
                </v-card-text>
            </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
    export default {

        


    }

</script>

