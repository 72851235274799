import settings from '../settings';
import axios from 'axios';

export default {

    getAll: function(){
        
        return axios.get(`${settings.apiURLPrefix}/account`);
    },

    addFacebookPage: function(userAccountID, pageID, instagramAccountID){

        return axios.post(`${settings.apiURLPrefix}/account/facebook/add-page`, {
            userAccountID       : userAccountID,
            pageID              : pageID,
            instagramAccountID : instagramAccountID
        });

    },

    removeAccount: function(accountID){

        return axios.delete(`${settings.apiURLPrefix}/account/${accountID}`);

    }


}