<template>
    <v-layout align-center justify-center>
        <v-flex xs12 sm10 md6 lg5>

            <v-expand-transition>
                <v-stepper v-model="currentStep" @input="onStepChange" v-if="!isSubmitting && !submitComplete">
                    <!-- STEPPER HEADER -->
                    <v-stepper-header>
                        <v-stepper-step
                            :complete="currentStep > 1"
                            editable
                            step="1"
                        >
                            Select Facebook Page
                        </v-stepper-step>

                        <v-divider></v-divider>

                        <v-stepper-step
                            :complete="currentStep > 2"
                            :editable="this.chosenPage.account_id != 0"
                            step="2"
                        >Connect to Instagram</v-stepper-step>

                        <v-divider></v-divider>

                        <v-stepper-step
                            :complete="currentStep > 3"
                            :editable="this.chosenPage.account_id != 0"
                            step="3"
                        >
                            Confirm
                        </v-stepper-step>
                    </v-stepper-header>
                    <!-- END STEPPER HEADER -->

                    <!-- STEP 1: CHOOSE PAGE -->
                        <v-stepper-content step="1">
                            <Step1ChoosePage ref="step1" :accountID="accountID" v-model="chosenPage" @next="gotoStep(2)" />
                        </v-stepper-content>
                    <!-- END STEP 1: CHOOSE PAGE -->

                    <!-- STEP 2: CHOOSE INSTAGRAM ACCOUNTS -->
                        <v-stepper-content step="2">
                            <Step2ConnectToInstagram ref="step2" :accountID="accountID" :page="chosenPage" v-model="instagramAccount" @next="gotoStep(3)" />
                        </v-stepper-content>
                    <!-- END STEP 2: CHOOSE INSTAGRAM ACCOUNTS -->

                    <!-- STEP 3: CONFIRM -->
                        <v-stepper-content step="3">
                            <Step3Confirm ref="step3" :accounts="allChosenAccounts" @next="submit" />
                        </v-stepper-content>
                    <!-- END STEP 3: CONFIRM -->

                </v-stepper>
            
            </v-expand-transition>

            <!-- LOADING -->
                <v-expand-transition>
                    <v-progress-circular
                        indeterminate
                        color="primary"
                        v-if="isSubmitting"
                    ></v-progress-circular>
                </v-expand-transition>
            <!-- END LOADING -->
            
            <!-- SUBMIT RESULT -->
                <v-dialog
                    v-model="submitComplete"
                    width="500"
                    max-width="80%"
                    persistent
                >
                    <v-card v-if="submitComplete">
                        <v-card-title class="headline">Yay</v-card-title>

                        <v-card-text>
                            The following accounts were added
                        </v-card-text>

                        <v-list class="mb-3">
                            <v-divider />

                            <template v-for="account in addedAccounts">
                                <v-list-tile :key="`account-${account.account_id}`" class="py-1">

                                    <v-list-tile-avatar>
                                        <img :src="account.profile_pic_small" />
                                    </v-list-tile-avatar>

                                    <v-list-tile-content>
                                        <v-list-tile-title v-html="account.account_name"></v-list-tile-title>
                                        <v-list-tile-sub-title v-html="account.platform"></v-list-tile-sub-title>
                                        
                                    </v-list-tile-content>

                                    <v-list-tile-action>
                                        <v-btn icon @click="$router.push(`/account/${account.id}/posts`)" color="info">
                                            <v-icon>mdi-eye-plus</v-icon>
                                        </v-btn>
                                    </v-list-tile-action>

                                    
                                </v-list-tile>
                                <v-divider :key="`account-divider-${account.account_id}`" />
                            </template>
                        </v-list>

                        <v-card-actions>
                            <v-spacer></v-spacer>

                            <v-btn
                                color="info"
                                flat="flat"
                                @click="$router.push('/')"
                            >
                                Ok
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            <!-- SUBMIT RESULT -->
        </v-flex>
    </v-layout>
</template>

<script>
    import Step1ChoosePage from './Step1ChoosePage';
    import Step2ConnectToInstagram from './Step2ConnectToInstagram';
    import Step3Confirm from './Step3Confirm';

    export default {
        components: {
            'Step1ChoosePage'               : Step1ChoosePage,
            'Step2ConnectToInstagram'       : Step2ConnectToInstagram,
            'Step3Confirm'                  : Step3Confirm
        },

        mounted: function(){
            this.redirectIfNotLoggedIn();
        },

        data: function(){
            return {
                currentStep: 0,
                chosenPage: {
                    account_id              : 0,
                    account_name            : "",
                    profile_picture_small   : "" 
                },
                instagramAccount: null,
                isSubmitting: false,
                submitComplete: false,
                addedAccounts: []
            };
        },

        computed: {
            accountID: function(){
                return this.$route.params.accountID;
            },

            allChosenAccounts: function(){
                let accounts = [this.chosenPage];
                if(this.instagramAccount)
                {
                    accounts = accounts.concat([this.instagramAccount]);
                }

                return accounts;
            }
        },

        methods: {
            gotoStep(whichStep)
            {
                if(whichStep - this.currentStep > 2)
                {
                    return;
                }

                if(whichStep > 1 && !this.chosenPage.account_id)
                {
                    return;
                }
                
                this.currentStep = whichStep;
            },

            onStepChange()
            {
                this.$refs[`step${this.currentStep}`].init();
            },

            submit()
            {
                this.isSubmitting = true;

                this.$store.dispatch('socialMediaAccounts/addFacebookPage', {
                    accountID               : this.accountID,
                    pageID                  : this.chosenPage.account_id,
                    instagramAccountID      : (this.instagramAccount)? this.instagramAccount.account_id : null
                }).then(accounts => {
                    
                    console.log(accounts);
                    this.addedAccounts = accounts;
                    this.isSubmitting = false;
                    this.submitComplete = true;

                }).catch(err => {

                    console.error(err);

                });
            }
        }


    }

</script>

