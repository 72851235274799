<template>
    <div>
        <v-dialog :value="value" v-model="isShown" max-width="800">
            
            <v-card>
                <v-card-title>
                    <span class="headline">Filter Posts</span>
                </v-card-title>
                <v-card-text>
                    <v-daterange ref="dateRangePicker" :options="dateRangeOptions" v-model="filters.dateRange" highlight-colors="primary" event-color="primary" :highlight-range="true"></v-daterange>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" flat @click="hide()">Cancel</v-btn>
                    <v-btn color="blue darken-1" flat @click="clearFilters">Clear Filters</v-btn>
                    <v-btn color="blue darken-1" flat @click="applyFilter">Apply</v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>

    </div>
</template>

<script>
    import { format, subDays } from 'date-fns';
    import { DateRange } from 'vuetify-daterange-picker';
    import 'vuetify-daterange-picker/dist/vuetify-daterange-picker.css';
    import moment from 'moment';

    export default {
        components: { 
            [DateRange.name]: DateRange 
        },

        props: {
            value: {
                dateRange: null
            }
        },

        data: function(){
            return {
                filters: {
                    dateRange: null
                },
                isShown: false,
                dateRangeOptions: {
                    format: 'DD/MM/YYYY',
                    presets: [
                        {
                            label: 'Today',
                            range: [
                                format(new Date(), 'YYYY-MM-DD'),
                                format(new Date(), 'YYYY-MM-DD'),
                            ],
                        },
                        {
                            label: 'Yesterday',
                            range: [
                                format(subDays(new Date(), 1), 'YYYY-MM-DD'),
                                format(subDays(new Date(), 1), 'YYYY-MM-DD'),
                            ],
                        },
                        {
                            label: 'Last 30 Days',
                            range: [
                                format(subDays(new Date(), 30), 'YYYY-MM-DD'),
                                format(subDays(new Date(), 1), 'YYYY-MM-DD'),
                            ],
                        }
                    ],
                }      
            };
        },

        methods: {

            show(){
                this.selectedDateRange = this.value;
                this.isShown = true;
            },

            hide(){
                this.isShown = false;
            },

            clearFilters(){
                this.filters.dateRange = null;

                this.$refs.dateRangePicker.endDate = undefined;
                this.$refs.dateRangePicker.startDate = undefined;
            },

            applyFilter(){
                this.hide();
                this.$emit('input', this.filters);
            }
        }


    }
</script>

<style lang="scss" scoped>
    
</style>