<template>
    <v-layout align-center justify-center>
        <v-flex xs12 sm8 md4>
            <v-card class="elevation-12">
                <v-toolbar color="black" dark>
                    <v-toolbar-title>Login</v-toolbar-title>
                </v-toolbar>
                
                <v-card-text>
                    
                    <!-- ERROR MESSAGE -->
                    <v-alert
                        :value="errorMsg !== false"
                        type="error"
                        :dismissible="true"
                    >
                        {{ errorMsg }}
                    </v-alert>
                    <br />

                    <v-form @submit="submit">
                        <v-text-field
                            prepend-icon="person" 
                            v-model="email"
                            label="Email"
                            required
                        ></v-text-field>

                        <v-text-field
                            prepend-icon="lock" 
                            v-model="password"
                            type="password"
                            label="Password"
                            required
                        ></v-text-field>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn right color="info" type="submit" :loading="isLoading">Log In</v-btn>
                        </v-card-actions>
                    </v-form>

                </v-card-text>

            </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
    export default {

        mounted: function(){
            if(this.$store.getters['auth/isLoggedIn'])
            {
                this.$router.push('/');
            }
        },

        data: function(){
            return {
                email       : "",
                password    : "",
                isLoading   : false,
                errorMsg    : false,

            };
        },

        methods: {
            submit: function(event){
                if (event) event.preventDefault();

                if(this.isLoading) return false;

                this.isLoading = true;
                this.errorMsg = false;

                this.$store.dispatch('auth/attemptLogin', {
                    email       : this.email,
                    password    : this.password
                }).then(()=>{

                    this.onLogin();

                }).catch((err)=>{

                    this.errorMsg = err;

                }).finally(()=>{
                    this.isLoading = false;
                });
                // return false;
            }
        }
    }
</script>
